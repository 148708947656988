import { render, staticRenderFns } from "./register.vue?vue&type=template&id=2500f115&"
import script from "./register.vue?vue&type=script&setup=true&lang=js&"
export * from "./register.vue?vue&type=script&setup=true&lang=js&"
import style0 from "./register.vue?vue&type=style&index=0&id=2500f115&prod&lang=scss&scope=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports